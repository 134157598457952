exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clinical-informatics-js": () => import("./../../../src/pages/clinical-informatics.js" /* webpackChunkName: "component---src-pages-clinical-informatics-js" */),
  "component---src-pages-collaborations-js": () => import("./../../../src/pages/collaborations.js" /* webpackChunkName: "component---src-pages-collaborations-js" */),
  "component---src-pages-data-science-js": () => import("./../../../src/pages/data-science.js" /* webpackChunkName: "component---src-pages-data-science-js" */),
  "component---src-pages-eds-js": () => import("./../../../src/pages/eds.js" /* webpackChunkName: "component---src-pages-eds-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-irods-js": () => import("./../../../src/pages/irods.js" /* webpackChunkName: "component---src-pages-irods-js" */),
  "component---src-pages-nrig-js": () => import("./../../../src/pages/nrig.js" /* webpackChunkName: "component---src-pages-nrig-js" */)
}

